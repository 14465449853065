import {Inject, Injectable} from '@angular/core';
import {Router} from "@angular/router";
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) { }

 sessionItems = ['traits','experiment_store']

  removeAllSessionItems(){
    console.log('destroy')
    this.sessionItems.forEach(item => {
      sessionStorage.removeItem(item)
    })
  }
  getSessionStorageItem(key: string) {
    const item = sessionStorage.getItem(key);
    return JSON.parse(item as string)
 }
  setSessionStorageItem(key: string, data: any) {
    sessionStorage.setItem(key, data)
  }
  removeSessionStorageItem(key: string) {
    sessionStorage.removeItem(key)
  }
  updateSessionStorageItem(key: string, data: any) {
    sessionStorage.removeItem(key)
    sessionStorage.setItem(key, data)
  }

  createHtmlId = (str: string, index: number) => str.split(' ').join('') + index;

  trackList(index: any, item: any) {
    return index
  }

  textAreaHeightHandler(event:any){
    event.currentTarget.style.height = event.currentTarget.scrollHeight + 'px';
  }

  alphabeticSort(a:any, b:any) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  }

   redirectToPathBasedOnHostname() {
     const hostname = this.document.location.hostname;
     switch (hostname) {
        case 'research.subconscious.ai':
          return this.router.navigate(['/experiments/research']);
        case 'design.subconscious.ai':
          return this.router.navigate(['/experiments/design']);
        default:
          return null;
     }
  }

  guardNewData(args:{newData:any[], oldData:any[]}){
    const arr1Ids = new Set(args.oldData.map((item) => item.id));
    console.log('arr1Ids',arr1Ids);
    return args.newData.filter((item) => !arr1Ids.has(item.id));
  }
}
