import {inject} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  createUrlTreeFromSnapshot,
  UrlTree
} from '@angular/router';
import {map} from 'rxjs';
import {AppAuthService} from "../../components/app-auth/app-auth.service";

export const AuthGuard = (route: ActivatedRouteSnapshot) =>{
  const authSrv = inject(AppAuthService);

  return authSrv.isAuthenticated$.pipe(
    map(res => res ? true : ((authSrv.login() ?? createUrlTreeFromSnapshot(route, ['/'])) as unknown as UrlTree))
  )
}
