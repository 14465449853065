import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable()
export class AuthTokenRevealInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const auth = request.headers.get('Authorization')?.replace('Bearer ', '');
    if(!environment.production && !!auth){

     // console.log("USER_TOKEN:", {token: auth})
    }

    return next.handle(request);
  }
}
