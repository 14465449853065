// const endpoint = 'https://superegobackend.azurewebsites.net'
//const endpoint = 'https://subconscious-superego.azurewebsites.net'
const endpoint = 'https://superegobackend.azurewebsites.net';
const origin = 'https://poc.subconscious.ai';

export const environment = {
  production: true,
  name: 'production',
  api: endpoint,
  origin: origin,
  HTJ_ID: '3385717',

  NODE_ENV_FIREBASE_API_KEY: 'AIzaSyCaHR2sTyIP2mNqU-IZhUEPHBobQaQN0VI',
  NODE_ENV_FIREBASE_AUTH_DOMAIN: 'why-earth.firebaseapp.com',
  NODE_ENV_FIREBASE_DATABASEURL:
    'https://why-earth-default-rtdb.firebaseio.com',
  NODE_ENV_FIREBASE_PROJECT_ID: 'why-earth',
  NODE_ENV_FIREBASE_STORAGE_BUCKET: 'why-earth.appspot.com',
  NODE_ENV_FIREBASE_MESSAGING_SENDER_ID: '465479038616',
  NODE_ENV_FIREBASE_APP_ID: '1:465479038616:web:a793251be1faaff15f02ed',
  NODE_ENV_FIREBASE_MEASUREMENT_ID: 'G-V58271NZJC',

  launch_list: 'https://getlaunchlist.com/s/MK2EzP',
  logo_link: './assets/images/brain_logo.png',
  docs_link: 'https://docs.subconscious.ai/',
  // shinyApp_link: "https://subconsciuos-r.azurewebsites.net",
  // shinyApp_origin: "https://subconsciuos-r.azurewebsites.net",
  shinyApp_link:
    'https://rshinyapp.eastus.cloudapp.azure.com/app_direct/subconscious-r-shiny/',
  shinyApp_origin: 'https://rshinyapp.eastus.cloudapp.azure.com',

  api_endpoints: {
    runs: `${endpoint}/runs`,
    artifacts: `${endpoint}/runs/artifact`,
    levels: `${endpoint}/levels`,
    traits: `${endpoint}/traits`,
    createExp: `${endpoint}/experiment/async`,
  },
  server_endpoints: {
    running_exp: `${origin}/api/meilisearch/running_exp`,
    create_exp: `${origin}/api/meilisearch/create_exp`,
    update_user: `${origin}/api/auth/updateUser`,
    sync: `${origin}/api/meilisearch/ms_sync`,
    causality: `${origin}/api/meilisearch/causality`,
  },

  meilisearch_endpoint: `https://ms-742a44f746e4-4744.nyc.meilisearch.io`,
  MEILISEARCH_API_KEY: '3ab505b26c1558b7268c9a529305fdd58ea53761',
  // meilisearch_endpoint: `https://ms-b0e1f8e6d55c-1532.sfo.meilisearch.io`,
  // MEILISEARCH_API_KEY: '9b4f666eaa8cef70683d47894f46223c43a8d0a5',

  MS_Indexes: {
    ms_exp: 'ms_experiments',
    ms_running_exp: 'ms_running_experiments_queue',
    ms_runs_graph: 'ms_runs_graph_data',
  },
  sentry_dns:
    'https://22be7d5337414a3484ca28f97cc4412e@o4504731197440000.ingest.sentry.io/4504765064544256',
  links: {
    discord_link: 'https://discord.gg/paMzAcqEQ2',
    journalist_inqueries_link: 'mailto:press@subconscious.ai',
    comercial_inqueries_link: 'mailto:ethicsboard@subconscious.ai',
  },

  authO_domain: 'https://dev-5qhuxyzkmd8cku6i.us.auth0.com',
  authO_clientId: '2y5g7skb8KiUeC5uTYrqhRrKjY3cvRJd',
  authO_redirect_uri: `${origin}/experiments/ideation`,
  authO_audience: 'https://dev-5qhuxyzkmd8cku6i.us.auth0.com/api/v2/',
};
