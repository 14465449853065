import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, finalize, Observable, throwError} from 'rxjs';
import {SentryService} from "../../utils/sentry-service/sentry.service";

@Injectable()
export class SentryErrorInterceptor implements HttpInterceptor {

  constructor(private srv: SentryService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const span = this.srv.createSentrySpan('http', `${request.method} ${request.url}`);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const title = `Http response error in ${error.url} url`;
        this.srv.sendSentryHttpError({title, requestBody:request.body, error, span});
        return throwError(() => new Error('Http response error'));
      }),
      finalize(() => {
        span.finish();
      })
    );
  }
}
