import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UnsupportedDeviceGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(){
    const screenWidth = window.innerWidth;
    const minScreenWidth = 1020;
    if (screenWidth < minScreenWidth) {
      this.router.navigate(['/unsupport']).then(r => console.log(r))
      return false;
    }
    return true;
  }


}
