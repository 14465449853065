import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {UnsupportedDeviceGuard} from "./guards/unsupported-device-guard/unsupported-device.guard";
import {SupportedDeviceGuard} from "./guards/supported-device-guard/supported-device.guard";
import {AuthGuard} from "./guards/auth-guard/auth.guard";



const routes: Routes = [
  {
    path:'',
    loadChildren: () => import('./home/home-routing/home-routing-routing.module').then(m => m.HomeRoutingRoutingModule),
    title: 'SubconsciousAI',
  },
  {
    path: 'experiments',
    loadChildren: () => import('./experiments/experiments-routing.module').then(m => m.ExperimentsRoutingModule),
    title: 'SubconsciousAI-experiments',
    canActivate: [UnsupportedDeviceGuard, AuthGuard],
  },
  {
    path:'unsupport',
    loadComponent: () => import('./components/unsupported-device-message/unsupported-device-message.component').then(m => m.UnsupportedDeviceMessageComponent),
    title: 'SubconsciousAI-unsupport',
    canActivate:[SupportedDeviceGuard]
  },
  { path: 'api', pathMatch: 'prefix', redirectTo: '' },
  { path: 'api/*', pathMatch: 'prefix', redirectTo: '' },
  {
    path: '**',
    loadComponent: () => import('./components/unknown-url/unknown-url.component').then(m => m.UnknownUrlComponent),
    title: 'SubconsciousAI-404',
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
