import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {SeverityLevel} from "@sentry/types/types/severity";
import {captureException, startTransaction, withScope} from "@sentry/angular-ivy";
import {Hub} from "@sentry/core/types/hub";

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  constructor() { }

  sendSentryHttpError(args:{title: string, requestBody: any, error: any , span?: any, level?: SeverityLevel}){
    const errorInstance = args.error.error instanceof Error ?
      args.error.error :
      new Error(`${args.title}: ${args.error.message}`);
    withScope(scope => {
      args.span ? scope.setSpan(args.span) : null;
      scope.setTransactionName(args.title);
      captureException(errorInstance,{
        level: args.level ? args.level : 'error',
        extra:{
          requestBody: args.requestBody,
          error: args.error
        },
        tags:{ environment: environment.name }
      })
    })

  }

  createSentrySpan(op: string, name: string):  ReturnType<Hub["startTransaction"]>{
    return startTransaction({op, name})
  }
}
