import {AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {GsapService} from "../../utils/gsap/gsap.service";




@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements AfterViewInit{
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private gsapSrv: GsapService,
  ) {}

  @Input() type: string | undefined;
  @Input() loaderMessage = 'Loading';

  @Input() color: string  = '#000000';
  @Input() borderWidth: string  = '2px';

 @ViewChild('dots') dots: ElementRef | undefined;
 str = '...';

   typesOptions: { [x:string]:string } = {
      loading: 'loading',
      bar: 'bar',
      spinner: 'spinner',
   }


private checkTypeExistence(){
  if(!this.type || !this.typesOptions[this.type]) throw new Error(`'type' is not defined. Available types: ${Object.values(this.typesOptions).join(', ')}`)
 }
  ngAfterViewInit(): void {
    this.checkTypeExistence();
    if(isPlatformBrowser(this.platformId)){
      this.gsapSrv.oneLineInfiniteTypeWriter(this.str, this.dots?.nativeElement);
    }
  }
}
