import {Component, OnInit} from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  ResolveEnd,
  ResolveStart,
  Router
} from "@angular/router";
import {filter, map} from "rxjs";
import {Title, TransferState, makeStateKey} from "@angular/platform-browser";
import {UtilsService} from "./utils/utils.service";




declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <app-loader *ngIf="loading$ | async" [type]="'loading'"></app-loader>
  `,
})
export class AppComponent implements OnInit {

  title = 'SubconsciousAI';

  constructor(
    private router: Router,
    private titleService: Title,
    private tstate: TransferState,
    private utilSrv:UtilsService,
  ) {


    /** START : Code to Track Page View using gtag.js */
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const url = event.urlAfterRedirects;
        this.trackPageView(url);
      })
    /** END : Code to Track Page View  using gtag.js */

    //Add dynamic title for selected pages - Start
    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        const title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
        titleService.setTitle(title);
      }
    });
  }



  loading$ = this.router.events.pipe(map(event => {
    return this.enableLoading(event)
}))

  ngOnInit(): void {
    this.utilSrv.redirectToPathBasedOnHostname();
  }

  getTitle(state:any, parent:any):any[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  private enableLoading(event: Event): boolean {
    if (event instanceof ResolveStart) return true
    if (event instanceof ResolveEnd || event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) return false
    return false
  }
  private getGtagFunction(): Function | null | void {
    const G_TAG_KEY = makeStateKey('gtag');
    return this.tstate.get(G_TAG_KEY, null);
  }

  private trackPageView(url: string): void {
    const gtag = this.getGtagFunction();
    if (gtag) {
      gtag('event', 'page_view', { page_path: url });
    }
  }


}
