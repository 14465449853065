import {Injectable} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";
import {EMPTY, Observable, switchMap, take} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable()
export class AppAuthService {

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) { }

  get isAuthenticated$(){
    return this.auth.isAuthenticated$;
  }

  get user(){
    return this.auth.user$ as Observable<any>;
  }


  login(){
    return this.auth.loginWithRedirect();
  }

  logOut(){
    return this.auth.logout();
  }

  updateUser(body:any){
    return this.http.patch(`${environment.server_endpoints.update_user}`, body)
  }



}
