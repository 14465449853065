import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init, Replay, routingInstrumentation } from '@sentry/angular-ivy';

//Sentry initialization
init({
  dsn: environment.sentry_dns,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new BrowserTracing({
      tracePropagationTargets: ['localhost', environment.api],
      routingInstrumentation: routingInstrumentation,
    }),
    // Registers and configures the Replay integration,
    // which automatically replays the user's actions after a crash
    // new Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
  //disable console.log in production
  window.console.log = function () {};
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      if ('serviceWorker' in navigator && environment.production) {
        navigator.serviceWorker.register('ngsw-worker.js');
      }
    })
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
