import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AngularFireModule} from "@angular/fire/compat";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {environment} from "../environments/environment";
import {LoaderComponent} from "./components-ui/loader/loader.component";
import {NgxHotjarModule, NgxHotjarRouterModule} from "ngx-hotjar";
import { LocationStrategy, PathLocationStrategy} from "@angular/common";
import {SentryErrorInterceptor} from "./interceptors/sentry-interceptor/sentry-error.interceptor";
import {createErrorHandler, TraceService} from "@sentry/angular-ivy";
import { ServiceWorkerModule } from '@angular/service-worker';
import {AppAuthModule} from "./components/app-auth/app-auth.module";
import {AuthTokenRevealInterceptor} from "./interceptors/authRevealToken/auth-token-reveal.interceptor";
import {Router} from "@angular/router";





@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: ErrorHandler,
      useValue:createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide:TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SentryErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenRevealInterceptor,
      multi: true,
    },
  ],
  imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: false }),
        NgxHotjarModule.forRoot(environment.HTJ_ID),
        NgxHotjarRouterModule,
        HttpClientModule,
        AppRoutingModule,
        AngularFireModule.initializeApp({
            apiKey: environment.NODE_ENV_FIREBASE_API_KEY,
            authDomain: environment.NODE_ENV_FIREBASE_AUTH_DOMAIN,
            databaseURL: environment.NODE_ENV_FIREBASE_DATABASEURL,
            projectId: environment.NODE_ENV_FIREBASE_PROJECT_ID,
            storageBucket: environment.NODE_ENV_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: environment.NODE_ENV_FIREBASE_MESSAGING_SENDER_ID,
            appId: environment.NODE_ENV_FIREBASE_APP_ID,
            measurementId: environment.NODE_ENV_FIREBASE_MEASUREMENT_ID,
        }),
        AngularFirestoreModule,
        LoaderComponent,
        AppAuthModule,


    ],
  bootstrap: [AppComponent],

})
export class AppModule  {}
