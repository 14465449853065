import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AuthModule} from "@auth0/auth0-angular";
import {AuthConfig} from "@auth0/auth0-angular/lib/auth.config";
import {environment} from "../../../environments/environment";
import {AppAuthService} from "./app-auth.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthHttpInterceptor} from "@auth0/auth0-angular";


const authConf:AuthConfig = {
  domain: environment.authO_domain,
  clientId:environment.authO_clientId,
  authorizationParams: {
    redirect_uri: environment.authO_redirect_uri,
    audience: environment.authO_audience,
  },
  httpInterceptor: {
   allowedList: [...Object.values(environment.api_endpoints), ...Object.values(environment.server_endpoints)],
  },
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthModule.forRoot(authConf)
  ],
  providers:[
    AppAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
})
export class AppAuthModule { }
