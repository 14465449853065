
<ng-container [ngSwitch]="type">
  <ng-container>
    <section *ngSwitchCase="typesOptions['loading']">
      <h3 class="loadingMessage">{{loaderMessage}}<span #dots></span></h3>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="typesOptions['bar']">
    <div class="progressbar" >
      <div class="stylization"></div>
    </div>
  </ng-container>


  <ng-container *ngSwitchCase="typesOptions['spinner']">
    <div class="lds-dual-ring" [style]="{'--loader-color': color, '--loader-border-width': borderWidth}"></div>
  </ng-container>

</ng-container>
